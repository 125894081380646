import './App.css';
// import { LandingPage  } from './components/LandingPage';
import MobileAppShowcase  from './MobileAppShowcase';


function App() {
  return (
    <MobileAppShowcase />
    // <LandingPage />
  );
}

export default App;
